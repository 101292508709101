.scroll-to-top {
    position: fixed; /* Fixiert den Button an der Ansicht */
    bottom: 20px; /* Abstand vom unteren Rand */
    right: 20px; /* Abstand vom rechten Rand */
    background-color: #d3d2d2; /* Graue Hintergrundfarbe */
    color: #3c3737; /* Textfarbe */
    border: none;
    border-radius: 5px; /* Abgerundete Ecken (optional) */
    width: 100px; /* Breite des Buttons */
    height: 40px; /* Höhe des Buttons */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    opacity: 1; /* Button ist zunächst unsichtbar */
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 1000; /* Stellt sicher, dass der Button über anderen Inhalten liegt */
  }
  
  .scroll-to-top:hover {
    background-color: #a8a7a7; /* Etwas dunkleres Grau beim Hover */
  }
  
  .scroll-to-top.show {
    opacity: 1; /* Button wird sichtbar, wenn die Klasse hinzugefügt wird */
    transform: translateY(0); /* Button wird in die Ansicht verschoben */
  }