.hero-slideshow {
  position: relative;
  height: calc(70vh - 120px);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.hero-slideshow img {
  opacity: 1;
}

.hero-slideshow h1 {
  font-size: 4rem; /* Größere Schriftgröße für den Titel */
  font-weight: 900; /* Fett für mehr Gewicht */
  margin-bottom: -40px;
}

.hero-slideshow h2 {
  font-size: 1.9rem; /* Größere Schriftgröße für den Titel */
  font-weight: 700; /* Fett für mehr Gewicht */
}

.hero-slideshow p {
  font-size: 1.4rem; /* Leicht größere Schriftgröße für bessere Lesbarkeit */
  font-weight: 500; /* Normalgewicht für den Fließtext */
  margin-bottom: 30px;
  max-width: 600px;
}

.hero-slide-2 p {
  font-size: 1.4rem; /* Leicht größere Schriftgröße für bessere Lesbarkeit */
  font-weight: 500; /* Normalgewicht für den Fließtext */
  margin-bottom: 30px;
  max-width: 1000px;
}

.hero-slide-2 {
  background: linear-gradient(to bottom right, #ced6f2, #eaebef); /* Farbverlauf Hintergrund */
  display: flex;
}

.hero-slide-2 .hero-image {
  position: absolute;
  width: 300px;
  top: 29%; /* Vertikale Zentrierung */
  left: 50%; /* Horizontale Zentrierung */
  transform: translate(-50%, -50%); /* Verschiebt das Bild zurück zur Mitte */
  max-width: 100%; /* Verhindert, dass das Bild größer als der Container wird */
  height: auto; /* Bewahrt das Seitenverhältnis */
}

/* Styling der Schaltflächen */
.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Styling der Punkte */
.dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #1abc9c;
}

.dot:hover {
  background-color: #16a085;
}

.container1 {
  display: none;
}

/* Die spezielle Klasse für Slide 2 */
.hero-slide-2 .container1 {
  display: block; /* Zeige das spezielle Element bei Slide 2 an */
}

.service-grid1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 280px;
  max-width: 1200px;
  z-index: 10;
}

.service1 {
  background-color: #fff; /* Hintergrundfarbe */
  border-radius: 10px; /* Abgerundete Ecken */
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Zentrierter Text */
}

.service1 h3 {
  margin-bottom: 10px;
}

.service1 p {
  font-size: 16px;
}

@media (max-width: 768px) {

  .hero-slide-2 .hero-image {
    position: absolute;
    width: 200px;
    top: 25%; /* Vertikale Zentrierung */
    left: 50%; /* Horizontale Zentrierung */
    transform: translate(-50%, -50%); /* Verschiebt das Bild zurück zur Mitte */
    max-width: 100%; /* Verhindert, dass das Bild größer als der Container wird */
    height: auto; /* Bewahrt das Seitenverhältnis */
  }


  .service-grid1 {
    display: flex;
    gap: 20px;
    margin-top: 250px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    position: relative;
    margin-left: 25%;
    margin-right: 25%;
    box-sizing: border-box;
}


.service1 {
    flex-shrink: 0;
    scroll-snap-align: start;
    max-width: 90%;
    box-sizing: border-box;
}

.hero-slideshow, .hero-slide-2 {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

  .prev, .next {
      padding: 8px 10px; /* Noch kleinere Padding für sehr kleine Bildschirme */
      font-size: 1.2rem; /* Kleinere Pfeile auf kleinen Bildschirmen */
      display: none; /* Verstecke die Pfeile auf kleinen Bildschirmen */
  }   

  .dot {
      height: 10px; /* Noch kleinere Dots */
      width: 10px; /* Noch kleinere Dots */
      margin: 0 3px; /* Engerer Abstand */
  }
}
