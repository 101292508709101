/* Allgemeine Stile */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  color: #333;
  background-color: #f9f9f9;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

h1, h2, h3 {
  margin: 20px 0;
}

p {
  margin: 10px 0;
}

/* Header */
 .header {
  background-color: #fff;/*#d6e2f0;*/
  color: #2c3e50;
  position: relative;
  height: 70px;  /* Gleiche Höhe wie das Logo */
  display: flex;
  align-items: center;  /* Zentriert das Logo vertikal */
  padding: 0 20px;  /* Optional: Seitliches Padding */
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav a {
  margin-left: 60px;
  color: #2c3e50;
  transition: color 0.3s;
  font-size: 14px;
}

.header-logo {
  height: 55px;  /* Feste Höhe */
  width: 132px !important;
  padding: 0;
  margin-top: 15px !important;
}


.nav a:hover {
  color: #1abc9c;
}

/* Call-to-Action Button */
.cta-button {
  background-color: #1abc9c;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #16a085;
}

/* Menu Icon for Mobile */
.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #2c3e50;
  margin: 3px 0;
  transition: 0.4s;
}

.change.bar {
  transform: rotate(45deg) translate(5px, 5px);
  background-color: #2c3e50;
}

.change.bar::after {
  content: '';
  display: block;
  transform: rotate(-90deg);
}

.nav {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  display: none;
  z-index: 1000;
}

.nav.open {
  display: flex;
}

.nav a {
  margin: 10px 0;
}

.cta-button {
  display: none;
}

.menu-icon {
  display: flex;
}

.change.bar {
  transform: rotate(45deg);
}

.change.bar::after {
  transform: rotate(-45deg);
  top: -8px;
}

.menu-icon .change:nth-child(1) {
  transform: translateY(8px) rotate(90deg);
}

.menu-icon .change:nth-child(2) {
  opacity: 0;
}

.menu-icon .change:nth-child(3) {
  opacity: 0;
}


/* Responsive Styles */
@media (max-width: 768px) {
    .nav {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        flex-direction: column;
        align-items: center;
        display: none;
        z-index: 1000;
    }

    .nav.open {
        display: flex;
    }

    .nav a {
        margin: 10px 0;
    }

    .cta-button {
        display: none;
    }

    .menu-icon {
        display: flex;
    }

    .change.bar {
        transform: rotate(45deg);
    }

    .change.bar::after {
        transform: rotate(-45deg);
        top: -8px;
    }

    .change.bar:nth-child(2) {
        opacity: 0;
    }

    .change.bar:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
    }

    .header-logo {
      height: 40px;  /* Feste Höhe */
      width: 96px !important;
      padding: 0;
      margin-top: 15px !important;
    }

    .header {
    
      height: 60px;  /* Gleiche Höhe wie das Logo */
    
    }


}

/* Hero Section */
.hero {
  background: url('hero-image.png') no-repeat center center/cover;
  color: #333 !important;
  text-align: center;
  position: relative;
  overflow: hidden; /* Verhindert, dass das Bild über den Container hinausgeht */
}

.hero h1, .hero h2, .hero p {
  background: linear-gradient(to right, #2c3e50, #4e667e); /* Der Farbverlauf von links nach rechts */
  -webkit-background-clip: text; /* Hintergrund des Verlaufs nur auf den Text anwenden */
  -webkit-text-fill-color: transparent; /* Textfarbe transparent setzen, um den Verlauf sichtbar zu machen */
  background-clip: text; /* Für andere Browser: Hintergrund des Verlaufs nur auf den Text anwenden */
  color: transparent; /* Textfarbe transparent setzen, um den Verlauf sichtbar zu machen */
}


.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Leicht hellere Überlagerung */
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2; /* Sicherstellen, dass der Inhalt über der Überlagerung liegt */
  margin: 0 auto; /* Zentriert den Inhalt */
  padding: 0 20px; /* Innenabstand für kleinere Bildschirme */
}

.hero h1 {
  font-size: 4rem; /* Größere Schriftgröße für den Titel */
  font-weight: 900; /* Fett für mehr Gewicht */
  letter-spacing: 10px;
  margin-bottom: -40px;
}

.hero h2 {
  font-size: 1.95rem; /* Größere Schriftgröße für den Titel */
  font-weight: 900; /* Fett für mehr Gewicht */
}

.hero p {
  font-size: 1.5rem; /* Leicht größere Schriftgröße für bessere Lesbarkeit */
  font-weight: 800; /* Normalgewicht für den Fließtext */
  margin-bottom: 30px;
}

/* Hero Section */
.hero {
  background: url('hero-image.png') no-repeat center center/cover;
  color: #fff;
  text-align: center;
  padding: 100px 0;
  position: relative;
  opacity: 0;  /* Startet unsichtbar für die Animation */
  transform: scale(1.1);  /* Startet leicht vergrößert für den Zoom-Effekt */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.hero.show {
  opacity: 1;  /* Sichtbar nach der Animation */
  transform: scale(1);  /* Normalgröße nach der Animation */
}

/* Animation beim Laden der Seite */
@keyframes fadeInZoom {
  from {
    opacity: 0;
    transform: scale(1.05);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.hero {
  animation: fadeInZoom 0.5s ease-out forwards;
}

/* Über Uns Section */
.about {
  background-color: #ecf0f1;
  padding: 50px 0;
  text-align: center;
}

.about p {
  max-width: 800px;
  margin: 0 auto;
}

.container img {
  width: 200px;
  margin-top: 25px;
}

/* Team Section */
.team {
  padding: 50px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.team-member {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.team-member img {
  width: 70%;
  border-radius: 50%;
  margin-bottom: 15px;
}

.team-member h3 {
  margin-bottom: 10px;
}

.team-member p:last-of-type {
  font-style: italic;
  color: #7f8c8d;
}

/* Services Section */
.services {
  padding: 50px 0;
  background-color: #ecf0f1;
  text-align: center;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.service {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service h3 {
  margin-bottom: 10px;
}

/* Contact Section */
.contact {
  padding: 50px 0;
  background-color:  #f9f9f9;
  text-align: center;
}

.contact-form {
  display: grid;
  gap: 15px;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
}

.contact-form button {
  background-color: #1abc9c;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #16a085;
}

.contact-info p {
  margin-bottom: 10px;
}

/* Footer */
.footer {
  background-color: #2c3e50;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  width: 100%;  /* Stellt sicher, dass der Footer die gesamte Breite einnimmt */
  box-sizing: border-box;  /* Bezieht Padding in die Gesamtbreite ein */
}

.footer .container {
  width: 90%;  /* Gleiche Breite wie andere Sektionen */
  max-width: 1200px;  /* Begrenzte maximale Breite */
  margin: 0 auto;  /* Zentriert den Footer auf der Seite */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer .nav1 {
  margin-top: 20px;
}

.footer .nav1 a {
  margin: 0 10px;
  color: #fff;
}

.footer .nav1 Link {
  margin: 0 10px;
  color: #fff;
}

.footer p {
  margin-top: 20px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .contact-form input,
  .contact-form textarea {
    font-size: 16px; /* Größe der Schrift für bessere Lesbarkeit */
    padding: 4px; /* Innenabstand anpassen */
  }
}

@media (max-width: 768px) {
  .footer .nav1 {
    flex-direction: column; /* Einträge untereinander auflisten */
    gap: 10px; /* Weniger Abstand zwischen den Einträgen */
  }
}


/* Responsiveness */
@media (max-width: 768px) {
  .hero h1 {
      font-size: 38px;
      letter-spacing: 5.5px;
  }

  .hero h2 {
      font-size: 18.5px;
  }

  .hero p {
      font-size: 18px;
  }

  .team-grid, .service-grid, .references-grid {
      grid-template-columns: 1fr;
  }
}

.impressum p{
  line-height: normal;

}

.impressum h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.3rem;
}

.email-button {
  background-color: #2c3e50; /* Primäre Farbe (Blau) */
  border: none; /* Kein Rand */
  border-radius: 5px; /* Runde Ecken */
  color: white; /* Textfarbe */
  padding: 12px 24px; /* Innenabstand */
  font-size: 1.1rem; /* Schriftgröße */
  cursor: pointer; /* Zeigt Hand-Cursor bei Hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Animiert Farbwechsel und Zoom-Effekt */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Schatten */
}

.email-button:hover {
  background-color: #4c6278; /* Dunklerer Blau-Ton beim Hover */
  transform: translateY(-3px); /* Leichtes Hochheben beim Hover */
}

.email-button:active {
  background-color: #4b6176; /* Noch dunklerer Blau-Ton beim Klick */
  transform: translateY(0); /* Zurücksetzen des Effekts */
}

.email-button:focus {
  outline: none; /* Entfernt den Standardfokus */
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.5); /* Fokusring */
}
